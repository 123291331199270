import { h } from "preact"
import { ContractFormData } from "./ContractView"
import { useContext } from "preact/hooks";
import { DataContext } from "src/App";
import { List, Space, Typography } from "antd";
import { ContractOffer } from "src/d/contract";
import useToken from "antd/es/theme/useToken";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import PriceDisplay from "../forms/PriceDisplay";
import { CSSProperties } from "preact/compat";
import TextArea from "antd/es/input/TextArea";

const ContractOfferView: React.FC<{ style?: CSSProperties, showTitle?: boolean, showDescription?: boolean, data: ContractFormData, contract: ContractOffer }> = ({ style, showTitle = true, showDescription = true, data, contract }) => {
  const { offers } = useContext(DataContext);
  const designToken = useToken()[1];

  const options = contract?.options?.filter(o => data.options[o.id]) ?? [];


  return !contract ? null : (
    <div style={style}>
      {showTitle && <Typography.Title>{contract.name}</Typography.Title>}
      {showDescription && <Typography.Paragraph>{contract.description}</Typography.Paragraph>}
      <Space size="large" direction="vertical" style={{ width: "100%" }}>
        <div style={{ flex: 1 }}>
          <List
            header={<span>Inhalt</span>}
            itemLayout="horizontal"
            dataSource={contract.includes}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<span>{item.qty ? item.qty === 1 ? <CheckOutlined style={{ color: designToken.colorSuccess }} /> : item.qty < 0 ? <CloseOutlined style={{ color: designToken.colorError }} /> : item.qty : ""}</span>}
                  title={item.name}
                  description={item.description}
                />
              </List.Item>
            )}
          />
        </div>
        {options.length > 0 &&
          <div style={{ flex: 1 }}>
            <List
              header={<span>Ausgewählte Optionen</span>}
              itemLayout="horizontal"
              dataSource={options}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<CheckOutlined style={{ color: designToken.colorSuccess }} />}
                    title={item.name}
                    description={item.description}
                  />
                </List.Item>
              )}
            />
          </div>
        }

        {contract.terms &&
          <div style={{ flex: 1 }}>
            <List
              header={<span>Vertragsbedingungen</span>}
              itemLayout="horizontal"
            >
              <List.Item>
                <TextArea
                  value={contract.terms}
                  disabled
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
              </List.Item>
            </List>
          </div>
        }

        { /* @NOTE: We sum up prices of selected options with same price.type and price.interval */}
        <div style={{ flex: 1 }}>
          <List
            header={<span>Preis</span>}
            itemLayout="horizontal"
            dataSource={(Array.isArray(contract.price) ? contract.price : [contract.price]).map(p => ({
              ...p,
              amount: p.amount +
                (contract.options ?? [])
                  .filter(o => data.options[o.id])
                  .reduce((prev, option) => prev + ((Array.isArray(option.price) ? option.price : [option.price]).find(o => o?.type === p.type && o.interval === p.interval)?.amount ?? 0), 0),
            })).filter(p => p.id === data.selectedPrice)}
            renderItem={(item, index) => (
              <div style={{ textAlign: 'right' }}>
                <PriceDisplay price={item} />
              </div>
            )}
          />
        </div>
      </Space>
    </div>
  )
}

export default ContractOfferView