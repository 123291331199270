import { StyleProvider } from "@ant-design/cssinjs";
import { createContext, h } from "preact";
import { ConfigProvider, Space } from "antd";
import { useEffect, useRef, useState } from "preact/hooks";
import { SizeType } from "antd/es/config-provider/SizeContext";
import ContractsTable from "./components/ContractsTable.tsx";
import StyleTest from "./components/StyleTest.tsx";
import ProcessEnvUsage from "./components/ProcessEnvUsage.tsx";
import TestComp from "./components/TestComp.tsx";
import Router, { route } from 'preact-router';
import { createHashHistory, createMemoryHistory } from "history";
import Link from "antd/es/typography/Link";
import useToken from "antd/es/theme/useToken";
import NewContractWiz from "./components/NewContractWiz.tsx";
import config from "./config.ts";
import { Address, ContractOffer, Geraet, Wartungsvertrag } from "./d/contract.ts";
import ContractForm from "./components/ContractForm.tsx";
import jsonQuery from "json-query";
import NewContract from "./components/NewContract.tsx";

interface IDataContext {
  loading: boolean;
  error?: string | null;
  addresses: any[];
  contracts: Wartungsvertrag[];
  devices: Geraet[];
  offers: ContractOffer[];
  token?: string | null;
}
export const DataContext = createContext<IDataContext>({
  loading: false,
  addresses: [],
  contracts: [],
  devices: [],
  offers: [],
});

const App = ({ token, size = "large", routing = "off" }: { token: string; size?: SizeType, routing?: "on" | "off" }) => {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const [stylesRoot, setStylesRoot] = useState<any>();

  // Howto use theme in js:
  const designToken = useToken()[1];
  const padding = designToken?.padding ?? 12;

  useEffect(() => {
    if (rootRef.current) {
      setStylesRoot(rootRef.current.getRootNode());
    }
  }, [rootRef]);

  // Data from API
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [contracts, setContracts] = useState<Wartungsvertrag[]>([]);
  const [devices, setDevices] = useState<Geraet[]>([]);
  const [offers, setOffers] = useState<ContractOffer[]>([]);
  const [error, setError] = useState<string | null | undefined>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const updateDta = () => {
    if (!token) {
      return () => { }
    }
    setLoading(true);
    setError(null);
    const responseHandler = (r: Response) => {
      if (!r.ok || r.status >= 300) {
        throw new Error(r.statusText)
      }
      return r.json()
    }
    const errorHandler = (err: any) => setError(err.toString())
    const featchOpts = { headers: { Authorization: `Bearer ${token}` } };
    // @TODO: add token for access here
    Promise.all([
      fetch(`${config.api}/api/addresses`, featchOpts)
        .then(responseHandler)
        .then(setAddresses)
        .catch(errorHandler),
      fetch(`${config.api}/api/contracts`, featchOpts)
        .then(responseHandler)
        .then(setContracts)
        .catch(errorHandler),
      fetch(`${config.api}/api/devices`, featchOpts)
        .then(responseHandler)
        .then(setDevices)
        .catch(errorHandler),
      fetch(`${config.api}/api/offers`, featchOpts)
        .then(responseHandler)
        .then(setOffers)
        .catch(errorHandler),
    ]).finally(() => { setLoading(false) })

    return () => { }
  };

  useEffect(updateDta, [token]);

  return (
    <div ref={rootRef}>
      {stylesRoot && (
        <StyleProvider container={stylesRoot as Element}>
          <ConfigProvider getPopupContainer={() => stylesRoot} theme={{ token: { colorPrimary: "#183146" } }}>
            <DataContext.Provider value={{ loading, error, addresses, contracts, devices, offers, token }}>

              <Space style={{ padding }}>
                { /* 
                <Link onClick={() => { route("/todo") }}>@TODO</Link> 
                <Link onClick={() => { route("/contracts") }}>Contracts</Link>
                <Link onClick={() => { route("/contract-wizard") }}>Contract WIZARD</Link>
                <Link onClick={() => { route("/contract-form") }}>Contract FORM</Link>
                */}
              </Space>

              <Router history={routing == "on" ? createHashHistory() : createMemoryHistory()}>
                <div path="/todo">
                  Todos:<br />
                  [x] Table for pending contracts (BACKEND)<br />
                  [x] User data from API<br />
                  [x] New contract data to API<br />
                  [-] New contract data to EMAIL<br />
                  [x] Connect API to upstream APIs (BACKEND)<br />
                  [x] BUG fix (scrollIntoView multiple locations + form validation errors)<br />
                  [-] Multiple WC-html-tags + DOCUMENTATION<br />
                  [-] DeepLink for user + device select<br />
                  [-] Usage for data quality enhancement<br />
                </div>
                <div path="/">
                  <ContractsTable size={size} />
                </div>
                <div path="/contracts">
                  <ContractsTable size={size} />
                </div>
                <div path="/contract-form">
                  <NewContract type="onepage" onFinished={updateDta} />
                </div>
                <div path="/contract-wizard">
                  <NewContract type="wizard" onFinished={updateDta} />
                </div>
              </Router>
            </DataContext.Provider>
          </ConfigProvider>
        </StyleProvider>
      )}
    </div>
  );
};

export default App;
