import { h } from "preact";
import React, { ReactElement, useContext, useEffect, useState } from "preact/compat";
import { Typography, Form, Radio, Space, List, Switch, Flex, FormInstance, Button } from 'antd';
import useToken from "antd/es/theme/useToken";
import styles from "bundle-text:./RadioCard.css";
import RadioCard from "./RadioCard";
import { DataContext } from "src/App";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import PriceDisplay from "./PriceDisplay";
import JsonQuery from "json-query";

const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 },
};

const tailLayout = {
    // wrapperCol: { offset: 8, span: 16 },
};

const ContractSelectForm: React.FC<{ selectedDeviceId?: number; cardStyle?: React.JSX.CSSProperties; showSubmit?: boolean; submitTitle?: ReactElement | string; onSubmit?: (data: any) => void, onChange?: (form: FormInstance) => void }> = ({ selectedDeviceId, cardStyle, onChange, onSubmit, showSubmit = false, submitTitle }) => {
    const [form] = Form.useForm();

    const { devices, offers, contracts } = useContext(DataContext);
    // We need this as state so we get consistent rerendering of all offers when changes happening
    const [currentValues, setCurrentValues] = useState<any>(null)
    const [selectedValue, setSelectedValue] = React.useState(null);

    const onRadioGroupChange = (e: any) => {
        const { value } = e.target;
        setSelectedValue(value);
    };
    // Howto use theme in js:
    const designToken = useToken()[1];

    const onFinish = (values: any) => {
        onSubmit && onSubmit(values);
        console.log(values);
    };

    const onChangeHandler = () => {
        setCurrentValues(form.getFieldsValue())
        onChange && onChange(form);
    };

    const onReset = () => {
        form.resetFields();
    };

    useEffect(() => {
        form.resetFields();
        setSelectedValue(null);
    }, [selectedDeviceId])

    // @TODO: Filter for selected OR available devices
    const availableDevices = selectedDeviceId ? devices.filter(d => d.GERAETID === selectedDeviceId) : devices.filter(d => !contracts.find(c => c.GERAETEID === d.GERAETID));
    const contractOffers = offers.filter(o => o.deviceFilter ? JsonQuery(o.deviceFilter, { data: availableDevices })?.value?.length : true);

    return (
        <Form
            {...layout}
            form={form}
            name="contract"
            onFinish={onFinish}
            requiredMark="optional"
            layout="vertical"
            onChange={onChangeHandler}

        >

            <style>{styles}</style>

            <Form.Item name="contract" rules={[{ required: true, message: "Bitte wählen Sie einen Vertrag aus." }]}>

                <Radio.Group value={selectedValue} onChange={onRadioGroupChange} style={{ display: "block" }}>
                    <Flex wrap="wrap" gap="middle">
                        {contractOffers.map(d => (
                            <RadioCard selected={currentValues?.contract === d.sku} style={cardStyle} value={d.sku} title={d.name}>
                                <Typography.Paragraph>{d.description}</Typography.Paragraph>
                                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                                    <div style={{ flex: 1, minWidth: 200, paddingRight: designToken.padding * 4 }}>
                                        <List
                                            header={<span>Inhalt</span>}
                                            itemLayout="horizontal"
                                            dataSource={d.includes}
                                            renderItem={(item, index) => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        avatar={<span>{item.qty ? item.qty === 1 ? <CheckOutlined style={{ color: designToken.colorSuccess }} /> : item.qty < 0 ? <CloseOutlined style={{ color: designToken.colorError }} /> : item.qty : ""}</span>}
                                                        title={item.name}
                                                        description={item.description}
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    </div>
                                    <div style={{ flex: 1, minWidth: 200, paddingRight: designToken.padding * 4 }}>
                                        <List
                                            header={<span>Optionen</span>}
                                            itemLayout="horizontal"
                                            dataSource={d.options}
                                            renderItem={(item, index) => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        avatar={<Form.Item name={["options", d.sku, item.id]} rules={[{ required: false, message: "" }]} initialValue={item.selected}>
                                                            <Switch
                                                                disabled={currentValues?.contract !== d.sku}
                                                                checkedChildren={<CheckOutlined />}
                                                                defaultChecked={item.selected}
                                                                onChange={onChangeHandler}
                                                            />
                                                        </Form.Item>}
                                                        title={item.name}
                                                        description={item.description}
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    </div>
                                    { /* @NOTE: We sum up prices of selected options with same price.type and price.interval */}
                                    <Form.Item name={["selectedPrice", d.sku]} style={{ marginBottom: 0 }} initialValue={(Array.isArray(d.price) ? d.price : [d.price]).find(p => p.selected)?.id} rules={[{ required: currentValues?.contract === d.sku, message: "Bitte wählen Sie die Zahlungsmodalitäten" }]}>
                                        <Radio.Group disabled={currentValues?.contract !== d.sku}>
                                            <List
                                                header={<span>Preis</span>}
                                                itemLayout="horizontal"
                                                dataSource={(Array.isArray(d.price) ? d.price : [d.price]).map(p => ({
                                                    ...p,
                                                    amount: p.amount +
                                                        (d.options ?? [])
                                                            .filter(o => form.getFieldValue(["options", d.sku, o.id]) /* currentValues?.options?.[d.sku]?.[o.id] */ === true)
                                                            .reduce((prev, option) => prev + ((Array.isArray(option.price) ? option.price : [option.price]).find(o => o?.type === p.type && o.interval === p.interval)?.amount ?? 0), 0),
                                                }))}
                                                renderItem={(item, index) => (
                                                    <List.Item>
                                                        <List.Item.Meta
                                                            avatar={<Radio value={item.id} defaultChecked={item.selected}></Radio>}
                                                        />
                                                        <div onClick={() => form.setFieldValue(["selectedPrice", d.sku], item.id)}>
                                                            <PriceDisplay price={item} />
                                                        </div>
                                                    </List.Item>
                                                )}
                                            />
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                            </RadioCard>
                        ))}
                    </Flex>
                </Radio.Group>
            </Form.Item>
            {showSubmit &&
                <Form.Item {...tailLayout}>
                    <Space>
                        <Button type="primary" htmlType="submit">
                            {submitTitle ?? "Submit"}
                        </Button>
                        {/** 
                        <Button htmlType="button" onClick={onReset}>
                            Reset
                        </Button>
                        */}
                    </Space>
                </Form.Item>
            }
        </Form>
    );
};

export default ContractSelectForm;