import { Descriptions, List } from "antd";
import DescriptionsItem from "antd/es/descriptions/Item";
import { h } from "preact";
import { CSSProperties } from "preact/compat";

export interface CustomerFormData {
  address: number;
  customer?: string;
  praxisName?: string;
  street?: string;
  city?: string;
}

const CustomerView: React.FC<{ style?: CSSProperties, data?: CustomerFormData | null }> = ({ style, data }) => {
  return (
    <div style={style}>
      <List
        header={<span>Kunde</span>}
        itemLayout="horizontal"
      >
        <List.Item>
          <List.Item.Meta
            avatar={<span></span>}
            title={data?.praxisName}
            description={<Descriptions column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }} size="small">
              {/*<Descriptions.Item label="Praxis">{data?.praxisName}</Descriptions.Item>*/}
              <Descriptions.Item label="Kundennummer">{data?.customer || '-'}</Descriptions.Item>
              <Descriptions.Item label="Adresse">
                <div>
                  {data?.street}<br />
                  {data?.city}
                </div>
              </Descriptions.Item>
            </Descriptions>
            }
          />
        </List.Item>

      </List>
    </div>
  )
}

export default CustomerView