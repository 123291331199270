import { h } from "preact";
import React, { ReactElement, useContext, useState } from "preact/compat";
import { Button, Card, Descriptions, Form, FormInstance, Input, Radio, Select, Space } from 'antd';
import useToken from "antd/es/theme/useToken";
import styles from "bundle-text:./RadioCard.css";
import RadioCard from "./RadioCard";
import { DataContext } from "src/App";
import JsonQuery from "json-query";
import { Geraet } from "src/d/contract";
import DeviceSpecs from "./DeviceSpecs";

const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 },
};

const tailLayout = {
    // wrapperCol: { offset: 8, span: 16 },
};

const DeviceSelectForm: React.FC<{ activeFilter?: string; cardStyle?: React.JSX.CSSProperties; showSubmit?: boolean; submitTitle?: ReactElement | string; onSubmit?: (data: any) => void, onChange?: (form: FormInstance) => void }> = ({ activeFilter, cardStyle, onChange, onSubmit, showSubmit = false, submitTitle }) => {
    const [form] = Form.useForm();
    const { devices, contracts, offers } = useContext(DataContext);
    // We need this as state so we get consistent rerendering of all offers when changes happening
    const [currentValues, setCurrentValues] = useState<any>(null)

    // Howto use theme in js:
    const designToken = useToken()[1];

    const onFinish = (values: any) => {
        onSubmit && onSubmit(values)
        // console.log(values);
    };

    const onChangeHandler = () => {
        setCurrentValues(form.getFieldsValue())
        onChange && onChange(form);
    }

    const onReset = () => {
        form.resetFields();
    };

    // @DONE: Filter by *[selected OR] available offers
    // *may not work
    const devicesWithoutContract = devices
        .filter(d => !contracts.find(c => c.GERAETEID === d.GERAETID))
        .filter(d => offers.find(o => o.deviceFilter ? JsonQuery(o.deviceFilter, { data: [d] })?.value?.length : true));

    const isActive = (device: Geraet) => !(activeFilter ? JsonQuery(activeFilter, { data: [device] }).value.length : true);

    return (
        <Form
            {...layout}
            form={form}
            name="device"
            onFinish={onFinish}
            requiredMark="optional"
            layout="vertical"
            onChange={onChangeHandler}
        >

            <style>{styles}</style>

            <Form.Item name="device" rules={[{ required: true, message: "Bitte wählen Sie ein Gerät." }]}>

                <Radio.Group>
                    <Space direction="horizontal" wrap>
                        {devicesWithoutContract.map(d => (
                            <RadioCard inactive={isActive(d)} style={cardStyle} selected={currentValues?.device === d.GERAETID} value={d.GERAETID} title={d.GERAETBEZEICHNUNG}>
                                <DeviceSpecs device={d} />
                            </RadioCard>
                        ))}
                    </Space>
                </Radio.Group>
            </Form.Item>
            {showSubmit &&
                <Form.Item {...tailLayout}>
                    <Space>
                        <Button type="primary" htmlType="submit">
                            {submitTitle ?? "Submit"}
                        </Button>
                        {/* 
                        <Button htmlType="button" onClick={onReset}>
                            Reset
                        </Button>
                        */}
                    </Space>
                </Form.Item>
            }
        </Form>
    );
};

export default DeviceSelectForm;