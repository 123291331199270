import { Descriptions, List, Typography } from "antd";
import { h } from "preact";
import { Geraet } from "src/d/contract";
import DeviceSpecs from "../forms/DeviceSpecs";
import { CSSProperties } from "preact/compat";

export interface CustomerFormData {
  customer: string;
  praxisName: string;
  street: string;
  city: string;
}

const DeviceView: React.FC<{ style?: CSSProperties, device: Geraet }> = ({ style, device }) => {
  return (
    <div style={style}>
      <List
        header={<span>Gerät</span>}
        itemLayout="horizontal"
      >
        <List.Item>
          <List.Item.Meta
            avatar={<span></span>}
            title={device.GERAETBEZEICHNUNG}
            description={<DeviceSpecs device={device} />}
          />
        </List.Item>

      </List>
    </div>
  )
}

export default DeviceView