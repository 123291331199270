import { Button, Descriptions, Space, Table, Typography } from "antd";
import type { TableColumnsType } from "antd";
import { Fragment, h } from "preact";
import React, { useContext } from "preact/compat";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { DataContext } from "src/App";
import { Geraet, Wartungsvertrag } from "src/d/contract";
import useToken from "antd/es/theme/useToken";
import DeviceView from "./views/DeviceView";
import PriceDisplay from "./forms/PriceDisplay";
import styles from "bundle-text:./ContractsTable.css"
import ContractStatus from "./views/ContractStatus";
import { PlusCircleOutlined } from '@ant-design/icons';
import { route } from "preact-router";
import JsonQuery from "json-query";
interface TableContract extends Wartungsvertrag {
  device?: Geraet;
}

const colums: TableColumnsType<TableContract> = [
  {
    title: "Vertrag",
    dataIndex: "BEZEICHNUNG",
    key: "BEZEICHNUNG",
    render: (value, record, index) => {
      // Howto use theme in js:
      const designToken = useToken()[1];

      return (
        <div style={{}}>
          <Typography.Title level={4} style={{ marginTop: 0 }}>{record.BEZEICHNUNG}</Typography.Title>
          <div style={{ padding: designToken.padding, paddingLeft: 2 * designToken.padding }}>
            <Descriptions column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }} size="small">
              <Descriptions.Item label="Typ"><strong>{record.ARTIKELBEZEICHNUNG}</strong></Descriptions.Item>
              <Descriptions.Item label="Artikelnummer">{record.ARTIKELNUMMER}</Descriptions.Item>
              <Descriptions.Item label="Start">{record.STARTDATUM ? new Date(record.STARTDATUM).toLocaleDateString() : "-"}</Descriptions.Item>
              <Descriptions.Item label="Ende">{record.ENDDATUM ? new Date(record.ENDDATUM).toLocaleDateString() : "-"}</Descriptions.Item>
              <Descriptions.Item label="Abrechnung">{record.INTERVALL_IM_MONATEN ? (record.INTERVALL_IM_MONATEN === 1 ? "monatlich" : record.INTERVALL_IM_MONATEN === 12 ? "jährlich" : `alle ${record.INTERVALL_IM_MONATEN} Monate`) : "-"}</Descriptions.Item>
              {record.LETZTE_ABRECHNUNG && <Descriptions.Item label="Letzte Abrechnung">{new Date(record.LETZTE_ABRECHNUNG).toLocaleDateString()}</Descriptions.Item>}
              {record.NAECHSTE_ABRECHNUNG && <Descriptions.Item label="Nächste Abrechnung">{new Date(record.NAECHSTE_ABRECHNUNG).toLocaleDateString()}</Descriptions.Item>}
            </Descriptions>
          </div>

        </div>
      )
    },
  },
  {
    title: "Status",
    dataIndex: "STARTDATUM",
    key: "STARTDATUM",
    className: 'gerl-tbl-valign-top',
    align: "center",
    render: (value, record, index) => {
      const designToken = useToken()[1];

      return (
        <div style={{ paddingTop: designToken.padding / 2 }}>
          <ContractStatus status={record.status} />
        </div>
      )
    },
  },
  {
    title: "Preis",
    dataIndex: "NAECHSTE_ABRECHNUNG",
    key: "NAECHSTE_ABRECHNUNG",
    className: 'gerl-tbl-valign-top',
    render: (value, record, index) => (
      <Fragment>
        <PriceDisplay price={{
          id: `none`,
          type: "recurring",
          currency: "EUR",
          amount: record.PREIS,
          interval: record.INTERVALL_IM_MONATEN,
          description: 'zzgl. Umsatzsteuer'
        }} />
      </Fragment>
    ),
    width: 200,
    hidden: false,
  },
];

const ContractsTable: React.FC<{ size: SizeType }> = ({ size }) => {
  const { loading, error, contracts, devices, offers } = useContext(DataContext)
  const designToken = useToken()[1];

  const contractsWithDevice: TableContract[] = contracts.map(c => ({ ...c, key: c.BEZEICHNUNG, device: devices.find(d => d.GERAETID === c.GERAETEID) }))
  // @DONE: Filter by *[selected OR] available offers
  // *may not work
  const devicesWithoutContract = devices
    .filter(d => !contracts.find(c => c.GERAETEID === d.GERAETID))
    .filter(d => offers.find(o => o.deviceFilter ? JsonQuery(o.deviceFilter, { data: [d] })?.value?.length : true));

  return (
    <Space direction="vertical" style={{ width: "100%" }} className="gerl-contracts-table">
      <style>{styles}</style>
      <Table
        size={size}
        columns={colums}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: (record) => record.device ? <div style={{ paddingLeft: designToken.padding }}>
            <DeviceView device={record.device} />
          </div> : null,
          rowExpandable: (record) => Boolean(record.device),
        }}
        dataSource={contractsWithDevice}
        footer={() => devicesWithoutContract.length ? <Button onClick={() => { route("/contract-form") }} type="primary" icon={<PlusCircleOutlined />} >Neuen Vertrag hinzufügen</Button> : null}
      />
    </Space>
  );
};

export default ContractsTable;
