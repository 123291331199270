import { useContext, useState } from "preact/hooks"
import ContractView from "./views/ContractView"
import type { NewContractData } from "./views/ContractView"
import { h } from "preact"
import ContractForm from "./ContractForm"
import NewContractWiz from "./NewContractWiz"
import config from "src/config"
import { DataContext } from "src/App"

const responseHandler = (r: Response) => {
  if (!r.ok || r.status >= 300) {
    throw new Error(r.statusText)
  }
  return r
}

const NewContract: React.FC<{ type?: "onepage" | "wizard", onFinished?: () => void }> = ({ type = "onepage", onFinished }) => {
  const [showConvScreen, setShowConvScreen] = useState(false)
  const [data, setData] = useState<NewContractData | null>(null)
  const [loading, setLoading] = useState<boolean>(false);
  const { token } = useContext(DataContext);

  const onFinish = (data: NewContractData) => {
    setData(data);
    setShowConvScreen(true);
  }

  const saveContractRequest = (data: NewContractData) => {
    setLoading(true)
    const request = fetch(`${config.api}/api/contracts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data)
    })
      .then(responseHandler)

    request.finally(() => {
      setLoading(false);
      if (onFinished) onFinished();
    });

    return request;
  }

  return (showConvScreen ?
    <ContractView onFinish={saveContractRequest} data={data} /> :
    type === "onepage" ?
      <ContractForm onFinish={onFinish} /> :
      <NewContractWiz onFinish={onFinish} />
  )
}

export default NewContract