import { Descriptions, List } from "antd";
import DescriptionsItem from "antd/es/descriptions/Item";
import { h } from "preact";
import { CSSProperties } from "preact/compat";
import { getCaption } from "../forms/AddressSpecs";
import { Address } from "src/d/contract";

const AddressView: React.FC<{ style?: CSSProperties, data?: Address | null }> = ({ style, data }) => {
  return data ? (
    <div style={style}>
      <List
        header={<span>Kunde</span>}
        itemLayout="horizontal"
      >
        <List.Item>
          <List.Item.Meta
            avatar={<span></span>}
            title={getCaption(data, false)}
            description={<Descriptions column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }} size="small">
              {/*<Descriptions.Item label="Praxis">{data?.praxisName}</Descriptions.Item>*/}
              <Descriptions.Item label="Kundennummer">{data['KD-NR'] || '-'}</Descriptions.Item>
              <Descriptions.Item label="Adresse">
                <div>
                  {data['Straße']}<br />
                  {data.PLZ} {data?.Ort}
                </div>
              </Descriptions.Item>
            </Descriptions>
            }
          />
        </List.Item>

      </List>
    </div>
  ) : null
}

export default AddressView