import { useEffect, useState } from "preact/hooks"
import { h } from "preact";
import { Space, Steps } from "antd";
import {
    ProfileTwoTone,
    ScheduleTwoTone,
    RocketTwoTone,
} from '@ant-design/icons';

import styles from "bundle-text:./NewContractWiz.css"

import CustomerForm from "./forms/CustomerForm";
import DeviceSelectForm from "./forms/DeviceSelectForm";
import ContractSelectForm from "./forms/ContractSelectForm";
import type { NewContractData } from "./views/ContractView";

const NewContractWiz: React.FC<{ size?: "small" | "default", onChange?: (data: NewContractData) => void, onFinish?: (data: NewContractData) => void }> = ({ size = "small", onChange, onFinish }) => {
    const [current, setCurrent] = useState(0);
    const [data, setData] = useState<any | null>(null);
    const [isReady, setIsReady] = useState<boolean>(false);

    const steps = [
        { title: "Kunde", icon: <ProfileTwoTone />, content: <CustomerForm showSubmit submitTitle="weiter" onSubmit={(d) => { setData((p: any) => ({ ...(p || {}), ...d })); setCurrent(1) }} /> },
        { title: "Gerät", icon: <RocketTwoTone />, content: <DeviceSelectForm showSubmit submitTitle="weiter" onSubmit={(d) => { setData((p: any) => ({ ...(p || {}), ...d })); setCurrent(2) }} /> },
        { title: "Vertrag", icon: <ScheduleTwoTone />, content: <ContractSelectForm selectedDeviceId={data?.device} showSubmit cardStyle={{ flex: 1, minWidth: 600 }} onSubmit={(d) => { setData((p: any) => ({ ...(p || {}), ...d })); setIsReady(true) }} /> },
    ]

    const items = steps.map((item) => ({ key: item.title, title: item.title, icon: item.icon }));

    const convertFormData: (fD: any) => NewContractData = (fD) => {
        return {
            ...fD,
            options: fD?.options?.[fD?.contract] ?? {},
            selectedPrice: fD?.selectedPrice?.[fD?.contract],
        }
    }

    useEffect(() => {
        onChange && onChange(convertFormData(data))
    }, [data])

    useEffect(() => {
        if (data && isReady) {
            onFinish && onFinish(convertFormData(data))
        }
    }, [isReady, data])

    return (
        <Space size="large" direction="vertical" style={{ width: "100%" }}>
            {size !== "small" && <style>{styles}</style>}
            <Steps direction="horizontal" size={size} current={current} items={items} />
            <div>
                {steps[current].content}
            </div>
        </Space>
    )
}

export default NewContractWiz