import { Descriptions } from "antd"
import { h } from "preact"
import { Geraet } from "src/d/contract"

const DeviceSpecs: React.FC<{ device: Geraet }> = ({ device }) => {
  return (
    <Descriptions column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }} size="small">
      <Descriptions.Item label="Raum">{device.RAUMBEZEICHNUNG || '-'}</Descriptions.Item>
      <Descriptions.Item label="Seriennummer">{device.SERIENNUMMER || '-'}</Descriptions.Item>
      <Descriptions.Item label="Hersteller">{device.HERSTELLER_NAME || device.HERSTELLER_ALPHASORT || '-'}</Descriptions.Item>
      <Descriptions.Item label="Installationsdatum">{device.INSTALLATIONSDATUM && new Date(device.INSTALLATIONSDATUM).getTime() > new Date().getTime() - 5 * 365 * 24 * 3600 * 1000 ? new Date(device.INSTALLATIONSDATUM).toLocaleDateString() : '-'}</Descriptions.Item>
    </Descriptions>
  )
}

export default DeviceSpecs