import { Card, Descriptions, Radio } from "antd"
import useToken from "antd/es/theme/useToken";
import { h } from "preact";
import { PropsWithChildren, useState } from "preact/compat";

const RadioCard: React.FC<PropsWithChildren<{ inactive?: boolean, style?: h.JSX.CSSProperties, value: any; title?: string; description?: string; selected?: boolean }>> = ({ inactive = false, selected, style, value, title, description, children }) => {
    const designToken = useToken()[1];

    return (
        <Card
            style={{ ...style, ...(selected ? { opacity: 1, borderColor: designToken.colorPrimary } : null) }}
            className={["gerl-radio-cart", inactive ? "gerl-radio-cart--inactive" : null].join(" ")}
        >
            <Radio value={value} style={{ width: "100%" }}>
                {title || description && (
                    <Card.Meta
                        title={title}
                        description={description}
                        style={{ paddingLeft: designToken.padding }}
                    />
                )}
                <div style={{ paddingLeft: designToken.padding, paddingTop: designToken.padding }}>
                    {children}
                </div>
            </Radio>
        </Card>
    )
}

export default RadioCard