import { Price } from "src/d/contract";
import { Fragment, h, render } from "preact";
import React, { ReactElement } from "preact/compat";
import { Statistic, Typography } from "antd";
import useToken from "antd/es/theme/useToken";

const timeFormatter = (monthCount: number) => {
  if (monthCount === 1) {
    return "Monat"
  } else if (monthCount % 12 === 0) {
    return monthCount === 12 ? "Jahr" : `${monthCount / 12} Jahre`
  }
  return `${monthCount} Monate`
}

const PriceDisplay: React.FC<{ price: Price; recurringSeperator?: ReactElement | string; valueStyle?: React.JSX.CSSProperties }> = ({ price, recurringSeperator = "/", valueStyle }) => {
  const designToken = useToken()[1];
  const vStayle = { color: designToken.colorPrimary, ...valueStyle }

  // Be aware of rouding stuff: https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
  // I think this solution: price.amount.toFixed(2), is appropiate in our use case
  return (
    <Fragment>
      <Statistic
        decimalSeparator=","
        groupSeparator="."
        title={price.name}
        value={price.amount.toFixed(2)}
        precision={2}
        valueStyle={vStayle}
        suffix={
          <Fragment>
            € {price.type === "recurring" && price.interval &&
              <Typography.Text type="secondary">{recurringSeperator} {timeFormatter(price.interval)}</Typography.Text>
            }
          </Fragment>}
      />
      {price.description && <Typography.Text type="secondary">{price.description}</Typography.Text>}
    </Fragment>
  )
}

export default PriceDisplay