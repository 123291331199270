import {
  QuestionCircleOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  IssuesCloseOutlined
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import useToken from 'antd/es/theme/useToken';
import { h } from 'preact';

const ContractStatus: React.FC<{ status?: 'unknown' | 'pending' | 'running' | 'ended' }> = ({ status }) => {
  const designToken = useToken()[1];

  switch (status) {
    case 'unknown':
      return <QuestionCircleOutlined />
      break;
    case 'pending':
      return <Tooltip title="in Bearbeitung"><ClockCircleOutlined style={{ color: designToken.colorWarning }} /></Tooltip>
      break;
    case 'running':
      return <Tooltip title="aktiv"><CheckCircleOutlined style={{ color: designToken.colorSuccess }} /></Tooltip>
      break;
    case 'ended':
      return <IssuesCloseOutlined style={{ color: designToken.colorError }} />
    default:
      return null
      break;
  }
}

export default ContractStatus