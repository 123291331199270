import { Fragment, h } from "preact";
import React, { ReactElement, useContext, useEffect, useRef, useState } from "preact/compat";
import { Button, Form, Select, Typography } from 'antd';
import CustomerForm from "./forms/CustomerForm";
import ContractSelectForm from "./forms/ContractSelectForm";
import DeviceSelectForm from "./forms/DeviceSelectForm";
import { DataContext } from "src/App";
import ContractView from "./views/ContractView";
import type { NewContractData } from "./views/ContractView";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { span: 16 },
};

const ContractForm: React.FC<{ submitTitle?: ReactElement | string; onChange?: (data: NewContractData) => void, onFinish?: (data: NewContractData) => void }> = ({ submitTitle, onChange, onFinish }) => {
  const [form] = Form.useForm();
  const { offers } = useContext(DataContext);

  const [data, setData] = useState<any | null>(null)
  const [customerValid, setCustomerValid] = useState(false)
  const [deviceValid, setDeviceValid] = useState(false)
  const [contractValid, setContractValid] = useState(false)

  const customerFormHeadRef = useRef<any>();
  const deviceFormHeadRef = useRef<any>();
  const contractFormHeadRef = useRef<any>();

  const convertFormData: (fD: any) => NewContractData = (fD) => {
    return {
      ...fD,
      options: fD?.options?.[fD?.contract] ?? {},
      selectedPrice: fD?.selectedPrice?.[fD?.contract],
    }
  }

  useEffect(() => {
    onChange && onChange(convertFormData(data))
  }, [data])

  useEffect(() => {
    if (customerValid && deviceValid && contractValid) {
      onFinish && onFinish(convertFormData(data))
    }
  }, [data, customerValid, deviceValid, contractValid])


  return (
    <Fragment>
      <Form.Provider
        onFormFinish={(formName, info) => {
          if (formName === "checkForm") {
            const { forms: { contract, customer, device } } = info;
            customer.validateFields().then(r => {
              setCustomerValid(true)
              setData((p: any) => ({ ...(p || {}), ...r }));
              // console.log(r)
            }).catch((err) => {
              setCustomerValid(false)
              setData((p: any) => ({ ...(p || {}), customer: null }));
              console.error(err);
              (customerFormHeadRef?.current as HTMLElement).scrollIntoView({ behavior: "smooth" });
            })

            device.validateFields().then(r => {
              setDeviceValid(true)
              setData((p: any) => ({ ...(p || {}), ...r }));
              // console.log(r)
            }).catch((err) => {
              setDeviceValid(false)
              setData((p: any) => ({ ...(p || {}), device: null }));
              console.error(err);
              (deviceFormHeadRef?.current as HTMLElement).scrollIntoView({ behavior: "smooth" });
            })

            contract.validateFields().then(r => {
              setContractValid(true)
              setData((p: any) => ({ ...(p || {}), ...r }));
              // console.log(r)
            }).catch((err) => {
              setContractValid(false)
              setData((p: any) => ({ ...(p || {}), contract: null }));
              console.error(err);
              (contractFormHeadRef?.current as HTMLElement).scrollIntoView({ behavior: "smooth" });
            })
          }
          // console.log(formName, info)
        }}
        onFormChange={(formName, info) => {
          const newVal = info.forms[formName].getFieldsValue();
          setData((p: any) => ({ ...(p || {}), ...newVal }));
          // console.log(formName, info, { ...(data || {}), ...newVal });
        }}

      >

        <Typography.Title ref={customerFormHeadRef} level={3}>Kunde</Typography.Title>
        <CustomerForm />

        <Typography.Title ref={deviceFormHeadRef} level={3}>Gerät</Typography.Title>
        <DeviceSelectForm activeFilter={offers.find(o => o.sku === data?.contract)?.deviceFilter} />

        <Typography.Title ref={contractFormHeadRef} level={3}>Vertrag</Typography.Title>
        <ContractSelectForm selectedDeviceId={data?.device} cardStyle={{ flex: 1 }} />

        <Form name="checkForm">
          <Form.Item {...tailLayout}>
            <Button htmlType="submit" type="primary">
              {submitTitle ?? "Submit"}
            </Button>
          </Form.Item>
        </Form>

      </Form.Provider >

      { /* <div>{JSON.stringify(data, null, 2)}</div> */}

    </Fragment>
  );
};

export default ContractForm;