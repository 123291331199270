import { Fragment, h } from "preact";
import React, { ReactElement, useContext, useState } from "preact/compat";
import { Button, Descriptions, Form, FormInstance, Input, Radio, Space, Typography } from 'antd';
import { DataContext } from "../../App";
import RadioCard from "./RadioCard";
import styles from "bundle-text:./RadioCard.css";
import AddressSpecs, { getCaption } from "./AddressSpecs";

const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 },
};

const tailLayout = {
    // wrapperCol: { offset: 8, span: 16 },
};

const CustomerForm: React.FC<{ cardStyle?: React.JSX.CSSProperties; showSubmit?: boolean; submitTitle?: ReactElement | string; onSubmit?: (data: any) => void; onChange?: (form: FormInstance) => void }> = ({ cardStyle, showSubmit = false, submitTitle, onSubmit, onChange }) => {
    const [form] = Form.useForm();
    const { addresses } = useContext(DataContext);
    // We need this as state so we get consistent rerendering of all offers when changes happening
    const [currentValues, setCurrentValues] = useState<any>(null);

    const onFinish = (values: any) => {
        onSubmit && onSubmit(values)
        // console.log(values);
    };

    const onReset = () => {
        form.resetFields();
    };

    const onFill = () => {
        form.setFieldsValue({ customer: "234567", praxisName: "SuperPraxis 007erl", street: "Superstr. 7", city: "8000 München" });
    };

    const onChangeHandler = () => {
        const values = form.getFieldsValue();
        setCurrentValues(values);
        if (values.address !== 0) {
            const a = addresses.find(({ ID }) => ID === values.address);
            if (a) {
                form.setFieldsValue({
                    customer: a["KD-NR"],
                    praxisName: [
                        a?.Name1,
                        a?.Name2,
                        a?.Name3,
                    ].filter(Boolean).join(' - '),
                    street: a["Straße"],
                    city: [a.PLZ, a.Ort].filter(Boolean).join(' ')
                });
            }
        }
        onChange && onChange(form);
    }


    return (
        <Form
            {...layout}
            form={form}
            name="customer"
            onFinish={onFinish}
            requiredMark="optional"
            layout="vertical"
            onChange={onChangeHandler}
        >
            <style>{styles}</style>

            <Form.Item name="address" rules={[{ required: true, message: "Bitte wählen Sie eine Anschrift." }]}>
                <Radio.Group>
                    <Space direction="horizontal" wrap>
                        {addresses.map(d => (
                            <RadioCard inactive={d?.Gueltig_Status} style={cardStyle} selected={currentValues?.address === d.ID} value={d.ID} title={getCaption(d)}>
                                <AddressSpecs address={d} />
                                {/*JSON.stringify(d)*/}
                            </RadioCard>
                        ))}
                        <RadioCard inactive={false} style={cardStyle} selected={currentValues?.address === 0} value={0} title={"Neue Adresse"}>

                            <Descriptions column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }} size="small">
                            </Descriptions>

                            {currentValues?.address === 0 ?
                                <div>
                                    <Form.Item label="Kundennummer" name="customer" rules={[{ required: true, message: "" }, { pattern: /^\d+$/, message: "Kundennummern bestehen ausschließlich aus Zahlen" }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="Praxis" name="praxisName" rules={[{ required: true, message: "" }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="Straße und Hausnummer" name="street" rules={[{ required: true, message: "" }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="PLZ und Stadt" name="city" rules={[{ required: true, message: "" }]}>
                                        <Input />
                                    </Form.Item>
                                </div> :
                                <Typography.Text type="secondary">
                                    Sie können einen neue Adresse anlegen.
                                </Typography.Text>
                            }
                        </RadioCard>
                    </Space>
                </Radio.Group>
            </Form.Item>
            {
                showSubmit &&
                <Form.Item {...tailLayout}>
                    <Space>
                        <Button type="primary" htmlType="submit">
                            {submitTitle ?? "Submit"}
                        </Button>
                        {/*
                        <Button htmlType="button" onClick={onReset}>
                            Reset
                        </Button>
                        <Button type="link" htmlType="button" onClick={onFill}>
                            Fill form
                        </Button>
                        */}
                    </Space>
                </Form.Item>
            }
        </Form >
    );
};

export default CustomerForm;