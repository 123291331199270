import { Descriptions } from "antd"
import { h } from "preact"

export const getCaption = (address: any, showPostalAddress: boolean = true) => {
  const fields = [
    [
      address?.Name1,
      address?.Name2,
      address?.Name3,
    ].filter(Boolean).join(' - '),
    showPostalAddress ? address["Straße"] : null,
    showPostalAddress ? address?.Ort : null
  ].filter(Boolean)

  return fields.join(' | ')
}

const AddressSpecs: React.FC<{ address: any }> = ({ address }) => {
  return (
    <Descriptions column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }} size="small">
      <Descriptions.Item label="Kundennummer">{address["KD-NR"] || '-'}</Descriptions.Item>
      <Descriptions.Item label="Email">{address["E-Mail"] || '-'}</Descriptions.Item>
      <Descriptions.Item label="Praxis">
        <div>
          {address.Name1 && <div>{address.Name1}</div>}
          {address.Name2 && <div>{address.Name2}</div>}
          {address.Name3 && <div>{address.Name3}</div>}
        </div>
      </Descriptions.Item>
      <Descriptions.Item label="Anschrift">
        <div>
          <div>{address["Straße"]}</div>
          <div>{address.PLZ} {address.Ort}</div>
        </div>
      </Descriptions.Item>
    </Descriptions>
  )
}

export default AddressSpecs