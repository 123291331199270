import { Button, Card, Checkbox, Result, Space, Typography } from "antd";
import { h } from "preact";
import CustomerView from "./CustomerView";
import type { CustomerFormData } from "./CustomerView";
import { DataContext } from "src/App";
import { useContext, useEffect, useRef, useState } from "preact/hooks";
import DeviceView from "./DeviceView";
import ContractOfferView from "./ContractOfferView";
import useToken from "antd/es/theme/useToken";
import Link from "antd/es/typography/Link";
import { route } from "preact-router";
import AddressView from "./AddressView";

export interface DeviceFormData {
  device: number;
}

export interface ContractFormData {
  contract: string;
  options: {
    [key: string]: boolean;
  };
  selectedPrice: string;
}

export interface NewContractData extends CustomerFormData, DeviceFormData, ContractFormData { }

const ContractView: React.FC<{ onFinish: (d: NewContractData) => Promise<any>; data?: NewContractData | null; }> = ({ onFinish, data }) => {
  const { devices, offers, addresses } = useContext(DataContext);
  const designToken = useToken()[1];

  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);


  const device = devices.find(d => d.GERAETID === data?.device)
  const contract = offers.find(o => o.sku === data?.contract)

  const padRFactor = 2
  const blockStyle = { paddingLeft: designToken.padding * padRFactor, paddingRight: designToken.padding * padRFactor }

  const onSubmit = () => {
    setSaving(true);
    onFinish(data!)
      .then(() => {
        // debugger;
        setSubmitted(true);
        setError(false);
      })
      .catch((err) => {
        // debugger;
        setError(true);
        setSubmitted(false);
        console.error(err);
      })
      .finally(() => setSaving(false))
  }

  const scrollToElem = useRef<any>();
  useEffect(() => {
    if (scrollToElem.current) {
      (scrollToElem.current as HTMLElement).scrollIntoView({ behavior: "smooth" });
    }
  }, [scrollToElem])

  return data && device && contract ? (
    <Card ref={scrollToElem}>
      {submitted ? (
        <Result
          status="success"
          title="Ihre Anfrage wurde erfolgreich versendet."
          subTitle="Diese wird nun geprüft und Sie erhalten eine Bestätigung."
          extra={[
            <Button type="primary" onClick={() => route("/contracts")}>
              Zur Vertragsübersicht
            </Button>
          ]}
        />
      ) : (
        <Space size="large" direction="vertical" style={{ width: '100%' }}>
          <Typography.Title>{contract.name}</Typography.Title>
          <Typography.Paragraph style={blockStyle}>{contract.description}</Typography.Paragraph>
          {data?.address === 0 ? <CustomerView style={blockStyle} data={data} /> : <AddressView style={blockStyle} data={addresses.find(adr => adr.ID === data.address)} />}
          {device && <DeviceView style={blockStyle} device={device} />}
          {data?.contract && contract && <ContractOfferView style={blockStyle} showTitle={false} showDescription={false} data={data} contract={contract} />}
          <div style={blockStyle}>
            <Checkbox onChange={(e) => setConfirmed(e.target.checked)} title="title"><span>Ich habe die <Link href="https://gerl-dental.de/agb" target="_blank">AGB</Link> gelesen, verstanden und stimme diesen zu.</span></Checkbox>
          </div>
          {error &&
            <div style={blockStyle}>
              <Typography.Text type="danger">Leider hat der Vorgang nicht funktioniert. Bitte versuchen Sie es noch einmal!</Typography.Text>
            </div>
          }
          <div style={blockStyle}>
            <Button type="primary" disabled={!confirmed || saving} loading={saving} onClick={onSubmit}>Absenden</Button>
          </div>
        </Space>
      )}

      { /* <div>{JSON.stringify(data, null, 2)}</div> */}

    </Card>
  ) : null
}

export default ContractView